<template>
	<div class="page_box">
		 
		<div class="bom_box">
			<div class="bom_box_list">

				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" >

					<!-- <el-table-column type="selection" width="55"></el-table-column> -->
					<el-table-column fixed prop="title" label="福利名称" width="" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<el-popover trigger="hover" placement="top" :content="scope.row.title">
								<div slot="reference" class="name-wrapper">
									{{ scope.row.title }}
								</div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="content" label="礼品名称" width="" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="cus_tiaojian" label="获取条件" width=""></el-table-column>
					<el-table-column prop="created_at" label="福利获取时间"></el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>

		<fuli_add_modal data-title="福利表单" ref="fuli_add_modal" @confirm="confirm_add_fuli" />

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的
	import fuli_add_modal from "@/components/fuli/fuli_add_modal.vue"; //减员

	export default {
		components: {
			page,
			fuli_add_modal
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由

				//提交数据
				forms: {
					year: '',
				},
				list: [],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围
			};
		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
		},
		mounted() {

		},
		methods: {
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("userGiftList", {
					
					...this.pages,
					userId:this.$route.query.id,
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						let list = res.data.list;
						list.forEach(v => {
							v.cus_file_title = v.attact && v.attact.name
							v.cus_file_url = v.attact && v.attact.url
							v.cus_tiaojian = v.cus_tiaojian || `工作满${v.day}天`
						})
						this.list = list
					} else {
						alertErr(res.msg)
					}
				});
			},

			do_add() {
				this.$refs.fuli_add_modal.init()
			},
			confirm_add_fuli() {
				this.pages.page = 1;
				this.get_list()
			},

		 

			 
		 
		},



	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}

	.name-wrapper {
		color: #2373C8;
	}

	.name-wrapper2 {
		color: #FF5E00;
	}

	.imgsss {
		width: 20px;
		height: 25px;

		img {
			width: 5px;
			height: 20px;
		}
	}

	.beizhushuoming {
		// border: 1px solid #000000;

		overflow: hidden;
		/* 确保超出容器的内容被裁剪 */
		white-space: nowrap;
		/* 确保文本在一行内显示 */
		text-overflow: ellipsis;
		/* 超出部分显示省略号 */
	}

	.houre {
		width: 293px;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #E4E4E4;
	}
</style>